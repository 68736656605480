import React, {Component} from 'react'
import Press from '../components/Press/Press'
import HomePage from '../components/Contents/Home/NeuchatelPage'
import HowItWorks from '../components/Contents/Home/HowIsWorks'
import MoreInfoSection from '../components/Contents/Home/MoreInfo'
import Footer from '../components/Footer/Footer'
import HeroSection from '../components/HeroSection/Hero'
import MobileActions from '../components/MobileActions/MobileActions'
import Helmet from 'react-helmet'
import Layout from '../layouts/index'


export default class extends Component {
    state = {
        isMobile: false,
        windowWidth: null,
        isLoading: true,
    }

    componentDidMount() {
        this.setState({
            isMobile: window.innerWidth < 768,
            windowWidth: window.innerWidth,
            isLoading: false
        }, () => {
            window.addEventListener("resize", this.updateDeviceState)
        })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDeviceState)
    }

    updateDeviceState = () => this.setState({ isMobile: window.innerWidth < 768, windowWidth: window.innerWidth})

    render() {

        console.log('index: ', this.props)

        return (

            <Layout location={this.props.location}>
                <div>
                    <Helmet>
                        <title>Psychiatre et Psychologue à Neuchatel | Psy-Scan Institute</title>
                        <meta name="description" content="Groupe de psychiatres et de psychologues à Neuchâtel offrant des prises en charge de psychiatrie et psychothérapie. Nous acceptons de nouveaux patients."/>
                    </Helmet>
                    <HeroSection {...this.state } {...this.props}/>
                    {/* <Carousel/> */}
                    {/* <Journals/> */}
                    <HomePage/>
                    <HowItWorks/>
                    {/* <Definitions isMobile={this.state.isMobile}/> */}
                    <div style={{height: 1, width: '100%', backgroundColor: 'rgb(234, 234, 234)'}} />
                    {/* <Press/> */}
                    <MoreInfoSection isMobile={this.state.isMobile} { ...this.props }/>
                    <Footer/>
                    {this.props.scrollPosition > 600
                        ? <MobileActions/>
                        : null
                    }
                </div>
            </Layout>
        )

    }
}


